



























































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../support/mixins/FiltersHandler.mixin.vue'

import FilterPopover from '../../Filters/partials/FilterPopover/FilterPopover.vue'

import { ListDisplayControlsProps } from '../ListDisplayControls.contracts'
import ListModeSwitch from '../../../molecules/ListModeSwitch/ListModeSwitch.vue'
import { ListMode } from '../../../molecules/ListModeSwitch'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ClosableControls>({
  name: 'ClosableControls',
  components: { FilterPopover, ListModeSwitch }
})
export class ClosableControls extends FiltersHandlerMixin {
  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: false, type: Number })
  public total!: ListDisplayControlsProps['total'] | null

  @Prop({ required: true, type: Boolean })
  public withCount!: boolean

  @Prop({ required: true, type: Boolean })
  public withModeSwitch!: boolean

  @Prop({ required: true, type: Boolean })
  public withVisibilityToggle!: boolean

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  /**
   * Defines whether filters are visible
   */
  public visible: boolean = true

  public mode: ListMode = ListMode.List

  public get areFiltersVisible (): boolean {
    return this.visible
  }

  public getLabel (paramConfig: FilterParamConfig) {
    if (!this.filters) {
      return ''
    }

    const value = this.filters[paramConfig.queryParam] as string

    return paramConfig.options[value] ?? ''
  }

  /**
   * Toggles filters visibility
   */
  public toggleVisibility (): void {
    this.visible = !this.visible
    this.$emit('setVisibility', this.visible)
  }

  public onSetMode (mode: ListMode): void {
    this.mode = mode
    this.$emit('setMode', mode)
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  private onChange (queryParam: string, value: string) {
    this.filters = this.updateFilter(this.filters, queryParam, value)
  }
}

export default ClosableControls
